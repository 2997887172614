import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Row } from "../Row";
import { TableFooter } from "../TableFooter";
import { IconNavigateNext } from "../../icons/IconNavigateNext";
import { TableHead } from "../TableHead";
import { IconCopy } from "../../icons/IconCopy";
import { IconNavigateBefore } from "../../icons/IconNavigateBefore/IconNavigateBefore";
import { StatusCompanyWorker } from "../StatusCompanyWorker";
import { IconButtonSmall } from "../../icons/IconButtonSmall";
import { BtnText } from "../../components/BtnText";
import { paths } from "../../_redux/_constants";
import { STORE } from "../../_redux/types";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import "./style.less";
import { handleCopy } from "../../helpers/clipboardHelper";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import { CopyableField } from "../CopyableField/CopyableField";

export const CompanyWorkersTable = ({ workers }) => {
  const navigate = useNavigate();
  const { companyWorkers, dictionaries } = useSelector(
    (state: STORE) => state.appData
  );

  function go_to_company_worker(id: number) {
    navigate(`/${paths.COMPANY_WORKERS_LIST}/:${id}`);
  }
  const tableHeadList = [
    {
      columnName: "name",
      text: "Name",
    },
    {
      columnName: "status",
      text: "Status",
    },
    {
      columnName: "role",
      text: "Role",
    },
    {
      columnName: "email",
      text: "Email",
    },
    {
      columnName: "phone",
      text: "Phone",
    },
  ];
  const tableColumns = {
    name: (itemData) => (
      <BtnText
        submitText={itemData.first_name + " " + itemData.last_name}
        handleClick={() => {
          go_to_company_worker(itemData.company_worker_id);
        }}
      />
    ),
    status: (itemData) => {
      const workerDeactivate =
        itemData.system_status ==
        dictionaries.system_statuses.find(
          (status) => status.code == "manager_operations_deactivate"
        )?.dict_id;
      return (
        <StatusCompanyWorker
          status={workerDeactivate ? "inactive" : "activate"}
        />
      );
    },
    role: (itemData) => {
      const roleText = dictionaries.company_roles.find(
        (role) => role.dict_id == itemData.role
      )?.lang_en;
      return <div className="text-main-13">{roleText || itemData.role}</div>;
    },
    email: (itemData) => <CopyableField value={itemData.e_mail} />,
    phone: (itemData) => {
      const formattedPhoneNumber = formatPhoneNumber(itemData.phone_number || '');
      return <CopyableField value={formattedPhoneNumber} />;
    },
  };

  return (
    <div className="table mb-0">
      <TableHead tableHeadList={tableHeadList} />
      <div className="table-body">
        <div className="rows">
          {workers.map((worker, i) => {
            return (
              <Row
                key={i}
                columnsList={tableHeadList}
                tableColumns={tableColumns}
                item={worker}
              />
            );
          })}
        </div>
      </div>
      <TableFooter listLength={workers.length} />
    </div>
  );
};


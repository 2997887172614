import React, { useState } from "react";

import { TableFooter } from "../TableFooter";
import { Row } from "../Row";
import { IconNavigateNext } from "../../icons/IconNavigateNext";
import { IconNavigateBefore } from "../../icons/IconNavigateBefore/IconNavigateBefore";
import { TableHead } from "../TableHead";

import "./style.less";

export const TableMain = ({ tableHeadList, tableColumns, drivers }) => {
  // Параметри пагінації
  const itemsPerPage = 20; // Кількість елементів на сторінку
  const [currentPage, setCurrentPage] = useState(1); // Поточна сторінка

  // Обчислення загальної кількості сторінок
  const totalPages = Math.ceil(drivers.length / itemsPerPage);

  // Обчислення індексів елементів для поточної сторінки
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Отримання данних поточної сторінки 
  const currentDrivers = drivers.slice(startIndex, endIndex);

  // Обробник переходу на наступну сторінку
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Обробник переходу на попередню сторінку
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="table mb-0">
      <TableHead tableHeadList={tableHeadList} />
      <div className="table-body">
        <div className="rows">
          {currentDrivers.map((driver, i) => {
            return (
              <Row
                key={i}
                columnsList={tableHeadList}
                tableColumns={tableColumns}
                item={driver}
              />
            );
          })}
        </div>
      </div>
      <TableFooter
        listLength={drivers.length}
        currentPage={currentPage}
        totalPages={totalPages}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage} />
    </div>
  );
};

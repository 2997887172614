import React, { useEffect } from "react";
// @ts-ignore
import sprite from "../../icons/sprite.svg";
import "./style.less";


export const TableFooter = ({
  listLength,
  currentPage,
  totalPages,
  handleNextPage,
  handlePreviousPage,
}) => {
  return (
    <div className="pages design-component-instance-node-2">
      <div className="table-footer-wrap">
        <div className="text-wrapper-4">
          Rows per page: 20 
        </div>
        <div className="pagination-controls">
        <span className="text-wrapper-4">
            {(currentPage - 1) * 20 + 1}-{Math.min(currentPage * 20, listLength)} of {listLength}
          </span>
          <button
            className="button-navigate"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <svg width="24px" height="24px" fill={currentPage === 1 ? "#d3d3d3" : "#6B6D82"}> 
              <use href={sprite + "#icon-navigate_before"} />
            </svg>
          </button>
          <button
            className="button-navigate "
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <svg width="30px" height="30px" fill={currentPage === totalPages ? "#d3d3d3" : "#6B6D82"}>
              <use href={sprite + "#icon-navigate_next"} />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
import React from "react";

import { ButtonColumns } from "../ButtonColumns";
import { FilterChip } from "../FilterChip";
import { Search } from "../Search";

import "./style.less";

type Props = {
  pageName: string;
  segmentedButton?: any;
  actionsBlock?: any;
  onSearch: any;
  data: any;
  keys: any;
  onSelectStatus: (status: string) => void; 
  selectedStatus: string[]; 
  isActiveTab: boolean; 


};
export const HeaderApplicantsList = ({
  pageName,
  segmentedButton = null,
  actionsBlock = null,
  onSearch,
  data,
  keys,
  onSelectStatus,
  selectedStatus,
  isActiveTab,
}: Props) => {
  return (
    <div className="head w-100">
      <div className="screen-list-header">
        <div className="screen-header-title-container">
          <div className="screen-header-title">{pageName}</div>
          {segmentedButton}
        </div>
        {actionsBlock}
      </div>
      <div className="filters">
        <div className="head-search-container">
          <Search
            placeholder={"Search"}
            onFiltered={onSearch}
            data={data}
            keys={keys}
          />
          <div className="frame-8">
            
            {/* рендеринг фільтрів лише для активної вкладки */}
            {isActiveTab && (
              <>
              <div className="text-gray-13-20">Status:</div>
                <FilterChip
                  label="New"
                  isSelect={selectedStatus.includes("New")}
                  onClick={() => onSelectStatus("New")}
                />
                <FilterChip
                  label="Pending"
                  isSelect={selectedStatus.includes("Pending")}
                  onClick={() => onSelectStatus("Pending")}
                />
                <FilterChip
                  label="Training"
                  isSelect={selectedStatus.includes("Training")}
                  onClick={() => onSelectStatus("Training")}
                />
                <FilterChip
                  label="Hiring"
                  isSelect={selectedStatus.includes("Hiring")}
                  onClick={() => onSelectStatus("Hiring")}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


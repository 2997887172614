import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

// @ts-ignore
import sprite from "../../icons/sprite.svg";

import { paths } from '../../_redux/_constants';
import { BtnText } from "../../components/BtnText";
import { TableMain } from "../../components/TableMain";
import { IconCopy } from "../../icons/IconCopy";
import { IconButtonSmall } from "../../icons/IconButtonSmall";
import { Application } from "../../_redux/types";
import { dateEdit } from "../../js/helpers";
import { handleCopy } from "../../helpers/clipboardHelper";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import { CopyableField } from "../CopyableField/CopyableField";

// import "./style.less";

export const TableReject = ({ drivers }) => {
  const navigate = useNavigate();

  function go_to_applicant(id: number) {
    navigate(`/${paths.APPLICANTS}/:${id}`);
  }
  const tableHeadList = [
    {
      columnName: "name",
      text: "Name",
    },
    {
      columnName: "email",
      text: "Email",
    },
    {
      columnName: "phone",
      text: "Phone",
    },
    {
      columnName: "applied_date",
      text: "Applied date",
    },
    {
      columnName: "rejected_date",
      text: "Rejected date",
    },
    {
      columnName: "reason_for_rejection",
      text: "Reason for rejection",
    },
    // {
    //   columnName: "action",
    //   text: "Action",
    // },
  ];
  const tableColumns = {
    name: (itemData: Application) => {
      const fullName = itemData?.driver_first_name || itemData?.driver_last_name
        ? itemData?.driver_first_name + ' ' + itemData?.driver_last_name
        : itemData.driver_ssn
    return <BtnText
      submitText={fullName}
      handleClick={ () => {go_to_applicant(itemData?.id)}}
    />},
    email: (itemData: Application) => <CopyableField value={itemData.email} />,
    phone: (itemData: Application) => {
      const formattedPhoneNumber = formatPhoneNumber(itemData.phone_number || '');
      return <CopyableField value={formattedPhoneNumber} />;
    },
    applied_date: (itemData: Application) => {
      const currentDateTime = Number(new Date(itemData?.create_time));
      const timeString = dateEdit(currentDateTime, false) as string;
      return <div className="table-text">{timeString}</div>
    },
    rejected_date: (itemData: Application) => {
      const currentDateTime = Number(new Date(itemData?.applic_time));
      const timeString = dateEdit(currentDateTime, false) as string;
      return <div className="table-text">{timeString}</div>
    },
    reason_for_rejection: (itemData: Application) => {
      const reason = JSON.parse(itemData.applic_status_description as string)?.reject_select_opt?.text
      return <div className="table-text">{reason}</div>
    },
    action: (itemData: Application) => (
      <div className="">
        <IconButton>
          <svg width="24" height="24">
            <use href={sprite + "#icon-refresh"}></use>
          </svg>
        </IconButton>
      </div>
    ),
  };
  return (
    <TableMain
      tableHeadList={tableHeadList}
      tableColumns={tableColumns}
      drivers={drivers}
    />
  );
};

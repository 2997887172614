import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { BtnText } from "../../../components/BtnText";
import { BtnRegistration } from '../../../components/BtnRegistration';
import { allConstants } from '../../../_redux/_constants';
import { STORE } from '../../../_redux/types';

import "./style.less";

type Props = {
  next: () => void,
}
export const RegGetStarted = ({
  next,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { company_information } = useSelector((state: STORE) => state.appData);

  const [touchedMC, setTouchedMC] = useState(false);

  const continuePage = () => {
    if(company_information['MC_Number']?.isCorrect) {
      next();
    }
  };

  const change_mc_number = (value) => {
    !touchedMC && setTouchedMC(true);
    const filteredValue = value.replace(/[^\d]+/g, '');

    dispatch({
      type: allConstants.SET_KEY_VALUE,
      payload: { key1: 'company_information', key2: 'MC_Number', value: filteredValue},
    });
  };

  return (
    <div className="regGetStarted">
      <div className="form-item screen-title">
        <div className="title">Get Started</div>
        <p className="screen-subtitle">Enter Your MC number for Create an account</p>
      </div>
      <div className="form-item mc_number">
        <TextField
          error={!company_information['MC_Number']?.isCorrect && touchedMC} // Помилка показується після спроби надіслати форму
          helperText={company_information['MC_Number']?.helperText || ''}
          fullWidth
          id="MC_Number"
          label="MC Number"
          placeholder=""
          value={company_information['MC_Number']?.value || ''}
          onChange={(event) => change_mc_number(event.target.value)}
          InputProps={{
            startAdornment: (<InputAdornment position="start"></InputAdornment>),
          }}
          className={`textField ${!company_information['MC_Number']?.isCorrect ? 'textFieldError' : ''}`}
        />
      </div>
      <div className="form-item btn-container">
        <BtnRegistration
          disabled={!company_information['MC_Number']?.isCorrect}
          onClick={continuePage}
          classes={'w-100'}
        />
        <div className="by-clicking-submit-wrapper">
          <p className="by-clicking-submit">
            <span className="font-small-gray">By clicking Submit button, you agree to the Oculus Freight </span>
            <span className="small-link">Terms of Use</span>
          </p>
        </div>
        <BtnText
          submitText={"Go to login"}
          handleClick={ () => {navigate("/login");}}
        />
      </div>
      <div className="screen-footer">
        <p className="footer-subtitle">
          The site is protected by reCAPTCHA and Google Privacy Policy and Terms of Service Apply.
        </p>
      </div>
    </div>
  );
};

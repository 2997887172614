import React, { useState } from "react";
import { handleCopy } from "../../helpers/clipboardHelper";
import { IconButtonSmall } from "../../icons/IconButtonSmall/IconButtonSmall";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { IconCopy } from "../../icons/IconCopy/IconCopy";

type CopyableFieldProps = {
    value: string; 
    className?: string;    
  };
  
  export const CopyableField = ({ value, className='' }: CopyableFieldProps) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopyClick = () => {
      handleCopy(value); 
      setCopied(true);
  
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    };
  
    return (
      <div className="row mx-0 align-items-center">
        {value && (
          <IconButtonSmall
            classes={"col"}
            configuration="grey"
            icon={
              copied ? (
                <svg width="20" height="20" fill="green">
                  <use href={sprite + "#icon-check"} />
                </svg>
              ) : (
                <IconCopy className="icon-copy cursor-pointer" color="#6B6D82" />
              )
            }
            stateProp="enabled"
            tooltipClassName="icon-button-small-instance"
            tooltipText="Copy"
            onClick={handleCopyClick}
          />
        )}
        <div className={`col-auto text-wrapper-2 px-0 mt-2px ${className}`} >{value}</div>
      </div>
    );
  };
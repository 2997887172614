import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from '../../_redux/_constants';
import { BtnText } from "../../components/BtnText";
import { TableMain } from "../../components/TableMain";
import { StatusDriverApplicants } from "../StatusDriverApplicants";
import { IconButtonSmall } from "../../icons/IconButtonSmall";
import { IconCopy } from "../../icons/IconCopy";
import { dateEdit } from "../../js/helpers";
import { Application } from "../../_redux/types";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import "./style.less";
import { handleCopy } from "../../helpers/clipboardHelper";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import { CopyableField } from "../CopyableField/CopyableField";

export const ApplicantsListTable = ({drivers}) => {
  const navigate = useNavigate();

  function go_to_applicant(id: number) {
    navigate(`/${paths.APPLICANTS}/:${id}`);
  }
  const tableHeadList = [
    {
      columnName: 'name',
      text: 'Name',
      containerClass: 'col-2'
    },
    {
      columnName: 'status',
      text: 'Status',
      containerClass: 'col-1'
    },
    {
      columnName: 'email',
      text: 'Email',
    },
    {
      columnName: 'phone',
      text: 'Phone',
      containerClass: 'col-2'
    },
    {
      columnName: 'driverse_licence_expires',
      text: 'Driverse Licence Expires',
      containerClass: 'col-2'
    },
    {
      columnName: 'applied_date',
      text: 'Applied date',
      containerClass: 'col-2'
    },
  ]

  const tableColumns = {
    name: (itemData: Application) => {
      const fullName = itemData?.driver_first_name || itemData?.driver_last_name
        ? itemData?.driver_first_name + ' ' + itemData?.driver_last_name
        : itemData.driver_ssn
    return <BtnText
      submitText={fullName}
      handleClick={ () => {go_to_applicant(itemData?.id)}}
    />},
    status: (itemData: Application) => <StatusDriverApplicants
      className="driver-applicants-status"
      status={itemData?.applic_status}
    />,
    email: (itemData: Application) => <CopyableField value={itemData.email} />,
    phone: (itemData: Application) => {
      const formattedPhoneNumber = formatPhoneNumber(itemData.phone_number || '');
      return <CopyableField value={formattedPhoneNumber} />;
    },
    driverse_licence_expires: (itemData) => {
      let timeString = "_____";
      if(itemData.driver_licenses[0]) {
        const currentDateTime = Number(new Date(itemData.driver_licenses[0]?.expires));
        timeString = dateEdit(currentDateTime, false) as string;
      }
      return <div className="">
        <div className="invited">{timeString}</div>
      </div>
    },
    applied_date: (itemData: Application) => {
      const currentDateTime = Number(new Date(itemData?.create_time));
      const timeString = dateEdit(currentDateTime, true) as string;
      return <div className="date">
        <div className="">{timeString}</div>
      </div>
    },
  }

  return (
    <TableMain tableHeadList={tableHeadList} tableColumns={tableColumns} drivers={drivers}/>
  );
};


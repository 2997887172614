import React from 'react';
import { IconCancel } from '../../icons/IconCancel';
import { IconArrowDropDown } from '../../icons/IconArrowDropDown';
import { IconsCancel24Px13 } from '../../icons/IconsCancel24Px13';
import { IconsCheck24Px8 } from '../../icons/IconsCheck24Px8';

import './style.less';

type Props = {
  label?: string;
  isSelect?: boolean;
  onClick: () => void;
};

export const FilterChip = ({ label = 'Label', isSelect = false, onClick  }: Props) => {
  return (
    <div
      className={`filter-chip ${isSelect ? 'selected' : ''}`}
      onClick={onClick}
    >
      <div className="state-layer-3">
        {isSelect && (
          <IconsCheck24Px8
            className="icon"
            color={'disabled' ? '#171F30' : '#181A2C'}
            // opacity={'disabled' ? '0.38' : undefined}
          />
        )}
        <span className="label-text-12">{label}</span>
      </div>
    </div>
  );
};
